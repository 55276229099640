import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from "material-table";
import {  
  CustomDialog, 
  LoadingProgress,
  TableIcons, 
  ToggleSwitch
} from '../ui'
import SettingsIcon from '@material-ui/icons/Settings'
import MuiAlert from '@material-ui/lab/Alert'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  TextField
} from '@material-ui/core'

import { Whatshot } from '@material-ui/icons'

import { commonStyle } from '../../styles'

import { clone } from '../../utilities/helperFunctions.js'

import CriterionList from './CriterionList.js'

import { 
  getAdFuelDisablementSiteList,
  saveAdFuelModuleConfiguration,
  saveAdFuelOptionFile,
  saveSiteCriteria
} from '../../modules/lookupsSlice.js'

export function AdFuelDisablement() {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [dialog, setDialog] = useState({coklntent:''})
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const { adFuelDisablementList } = useSelector(state => state.lookups)
  const [siteData, setSiteData] = useState(clone(adFuelDisablementList))

  const showModifyDisablementDialog = (data) => {
    setDialog({
      size: 'sm',
      content: (
        <ModifyDisablementDialog data={data} setDialogIsOpen={setDialogIsOpen} updateSiteData={updateSiteData} />
      ),
      displayDialogAction: false
    })
    setDialogIsOpen(true)
  }

  /* 
  --->  newRecord is of this type: <---
  {
    _id : string
    siteName: string
    siteDesc: string
    disablementCriteria: [{criterion: string, description: string}]
    disablementEnabled : boolean (not stored in DB but constructed in the query if DISABLE_ADFUEL has a value in the config)
    configDoc: {
      _id : string
      siteId : string
      adFuelModuleId : string
      key : string
      configuration: any **this one
    }
  }
  */
  const updateSiteData = (newRecord) => {
    setIsLoading(true)
    // ALWAYS delete this key -- default state this key should not exist
    if (newRecord.configDoc.configuration.DISABLE_ADFUEL) { 
      delete(newRecord.configDoc.configuration.DISABLE_ADFUEL)
    }

    // disablementEnabled gets its value from the toggle switch in the edit dialog.
    // So...DISABLE_ADFUEL should only exist if disablement is enabled.
    if (newRecord.disablementEnabled) {
      newRecord.configDoc.configuration.DISABLE_ADFUEL = groomCriteria(newRecord.disablementCriteria.map(c => {return c.criterion}).join(',')) 
    }

    const optFile = {
      name : newRecord.siteName, 
      options: newRecord.configDoc.configuration
    }
    console.log('---sending to saveAdFuelOptionFile', optFile)
    saveAdFuelOptionFile(optFile)
    .then((result) => {
      console.log('---Results of saveAdFuelOptionFile:', result)
      if (result.status === 200) {
        const updateDoc = {
          site: newRecord.configDoc.siteId,
          module: newRecord.configDoc.adFuelModuleId,
          key: newRecord.configDoc.key,
          config: newRecord.configDoc.configuration
        }
        console.log('---sending to saveAdFuelConfiguration', updateDoc)
        saveAdFuelModuleConfiguration(updateDoc)
        .then((result) => {
          console.log('---Results of saveAdFuelConfiguration:', result)
          saveSiteCriteria(newRecord.configDoc.siteId, newRecord.disablementCriteria)
          .then(result => {
            if (result.status === 200) {
              let siteDataClone = clone(siteData)
              dispatch(getAdFuelDisablementSiteList())
              setSiteData(siteDataClone)
            }
          })
          
        })
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (adFuelDisablementList.length === 0) {
      dispatch(getAdFuelDisablementSiteList())
    } else {
      console.log('Shrubbery?  No thanks, Ive already got one!')
      setSiteData(clone(adFuelDisablementList))
    }
    setIsLoading(false)
  }, [adFuelDisablementList])

  return (
    <Container maxWidth='xl' style={{cursor: isLoading ? 'wait' : 'default' }}>
        <Card>
            <CardHeader title='AdFuel Disablement' />
            <CardContent className={commonClasses.cardBkClr}>
                {isLoading
                  ? (
                    <LoadingProgress label='Loading' />
                    )
                  : (
                    <MaterialTable
                      titles={'Sites'}
                      icons={TableIcons}
                      data={ siteData }
                      options={{
                        emptyRowsWhenPaging: false,
                        pageSize: 10,
                        pageSizeOptions:[10,20,50],
                        selection:false,
                        headerStyle: {
                            fontWeight: 'bold'
                        }
                      }}
                      columns={[
                        { title: 'Site Name', field: 'siteName' },
                        { title: 'Site Description', field: 'siteDesc' },
                        { title: 'Ads are disabled?', 
                          field: 'disablementEnabled',
                          render: (params) => {
                            if (Boolean(params.disablementEnabled)) {
                              return <span style={{ color: 'red'}}><strong>YES</strong></span> 
                            } else {
                              return <span style={{ color: 'green'}}><strong>NO</strong></span>
                            }
                          }
                        }
                      ]}
                      actions={[
                        {
                          icon: SettingsIcon,
                          tooltip: 'Modify',
                          onClick: (event, data) => {
                            showModifyDisablementDialog(data)
                          }
                        },
                      ]}
                    />
                  )
                }

                <CustomDialog
                  open={dialogIsOpen}
                  setOpenDialog={setDialogIsOpen}
                  title={dialog.title}
                  subtitle={dialog.subtitle}
                  content={dialog.content}
                  displayDialogAction={dialog.displayDialogAction}
                  customAction={dialog.customAction}
                  size={dialog.size}
                />

            </CardContent>
        </Card>
    </Container>
  )
}


function ModifyDisablementDialog({data, setDialogIsOpen, updateSiteData}) {
  console.log('DATA:', data)

  const commonClasses = commonStyle()
  const swal = withReactContent(Swal)

  const [disablementEnabled, setDisablementEnabled] = useState( data.disablementEnabled )
  const [testUrl, setTestUrl] = useState('')
  const [passFail, setPassFail] = useState(testDisablement(data.disablementCriteria.map(c => {return c.criterion}).join(','), testUrl))
  const [disablementCriteria, setDisablementCriteria] = useState(data.disablementCriteria)

  const passFailText = (passFail) => {
    let  msg = passFail 
      ? 'Result: ADFUEL DISABLED.' 
      : 'Result: ADFUEL NOT DISABLED.'
    console.log('Returning: ', msg)
    return msg
  }

  const deleteFromCriteria = (index) => {
    let clonedCriteria = JSON.parse(JSON.stringify(disablementCriteria))
    clonedCriteria.splice(index,1)
    setDisablementCriteria(clonedCriteria)
  }

  const addToCriteria = (criterionToAdd) => {
    let clonedCriteria = JSON.parse(JSON.stringify(disablementCriteria))
    clonedCriteria.push(criterionToAdd)
    setDisablementCriteria(clonedCriteria)
  }

  const handleEnable = (e) => {
    // Set data to 'true' if enabled
    if (disablementCriteria.length < 1 && e.target.checked) {
      setDisablementCriteria([{criterion: "true", description:"Default"}])
    }
    // if data is 'true' and we are disabling, emtpy data.
    // if (disablementData==='true' && !e.target.checked) {
    //   setDisablementData('')
    // }

    setDisablementEnabled(e.target.checked)
  }

  return (
    <Card>
    <CardHeader title={`Modify Disablement for [${data.siteName}]: ${data.siteDesc}`} />
    <Divider />
    <CardContent>
      <ToggleSwitch
        label='Enable Disablement'
        name='registrySingleton'
        offLabel='No'
        onLabel='Yes'
        checked={disablementEnabled}
        value={disablementEnabled}
        onChange={(e) => {
          handleEnable(e)
        }}
      />  
      <CriterionList criteria={disablementCriteria} removeCriteria={deleteFromCriteria} addCriteria={addToCriteria} />
    </CardContent>
    <Divider />
    <CardContent>
        <Grid container style={{ marginBottom: '10pt'}}>
          <Grid item md={4} xs={12}>
            <TextField
              style={{ width: '99%'}} 
              label='URL'
              margin='dense'
              name='testUrl'
              defaultValue=''
              variant='outlined'
              onChange={event => {
                setTestUrl(event.target.value)
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant='contained'
              color='primary'
              className={[
                commonClasses.btnInfo,
                commonClasses.m_1
              ].join(' ')}
              startIcon={<Whatshot />}
              onClick={() => {
                let didItPass = false
                try {
                  didItPass = testDisablement(groomCriteria(disablementCriteria.map(c => {return c.criterion}).join(',')), testUrl)
                  setPassFail(didItPass)
                } catch {
                  didItPass = false
                }                
              }}
            >
              Test
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              variant='contained'
              className= {
                passFail ? commonClasses.btnDanger : commonClasses.btnSuccess
              }
            >
              { disablementEnabled 
                  ? passFailText(passFail)
                  : ''}
            </Box>
          </Grid>
        </Grid>        
    </CardContent>
    <Box mt={3}>
      <MuiAlert variant='filled' severity='info'>
        <List subheader='Note' dense>
          <ListItem dense>
            A REGEX is proceded and ended with a / character.  
          </ListItem>
          <ListItem dense>
            If a comma is part of the cirterion, make it a REGEX string like: /test,me/
          </ListItem>
          <ListItem dense>
            Here is an example of a REGEX: /ncaa.com(?:\\?.*)?(?:/(?:\\?.*)?)?$/ 
          </ListItem>
        </List>
      </MuiAlert>
    </Box>
    <Divider />
    <CardActions className={commonClasses.actionArea}>
      <Button variant='text' onClick={() => setDialogIsOpen(false)}>
        Cancel
      </Button>
      <Button
        className={commonClasses.btnSuccess}
        variant='contained'
        disabled = {disablementCriteria.length < 1}
        onClick={() => {
          setDialogIsOpen(false)
          swal.fire({
            title: 'Are you sure?',
            text: disablementEnabled 
               ? `This will DISABLE AdFuel delivered ads for [${data.siteName}]: ${data.siteDesc}.` 
               : `This will ENABLE AdFuel delivered ads for [${data.siteName}]: ${data.siteDesc}.` ,
            icon: 'warning',
            showCancelButton: true
            // buttons: true,
            // dangerMode: true
          }).then(ok => {
            if (ok.isConfirmed) {
              updateSiteData({
                _id : data._id,
                siteName: data.siteName,
                siteDesc: data.siteDesc,
                disablementEnabled : disablementEnabled,
                disablementCriteria: disablementCriteria,
                configDoc: data.configDoc,
              })
            } 
          })
        }}
      >
        Apply
      </Button>
    </CardActions>
  </Card>
  )


  function testDisablement(criteria, url) {
    let isIt = false;
    const pageUrl = url;

    let evalString = (criteriaString) => {
      if (/^\/.*\//.test(criteriaString)) {
        try {
          return new RegExp(criteriaString.slice(1, -1), "i").test(pageUrl);
        } catch (ex) {
          console.error("!!! AdFuel isDisabled RegExp threw:", ex);
          return false;
        }
      } else {
        return pageUrl.toUpperCase().includes(criteriaString.toUpperCase());
      }
    };

    if (typeof criteria == "boolean") {
      isIt = criteria;
    } else if (typeof criteria === "string") {
      isIt = evalString(criteria);
    } else if (Array.isArray(criteria)) {
      for (let c of criteria) {
        if (evalString(c)) {
          isIt = true;
          break;
        }
      }
    }

    if (isIt) console.warn(`!!! AdFuel has been disabled !!!`);
    return isIt;
  }
}

  //Designed to return actual JSON criteria object from a string
  // should return true/false or an array of criteria including string and regex
  function groomCriteria(criteriaText) {
    console.log('Grooming: ', criteriaText)
    const groom = (criterion) =>{
      if ((criterion==='true') || (criterion==='false')) {
        if (criterion==='true') {
          return true
        } else {
          return false
        }
      } else {
        return criterion
      }
    }

    // Split comma deliniated list with quoted strings that contain commas.
    /* /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g */
    /* .replace(/["]+/g,'') */

    // Split comma deliniated list with regex strings that contain commas
    /* /,(?=(?:[^\/]*\/[^\/]*\/)*[^\/]*$)/g */
    
    if (/,(?=(?:[^\/]*\/[^\/]*\/)*[^\/]*$)/g.test(criteriaText)) {
      //let splitCriteria = criteriaText.split(',')
      let splitCriteria = criteriaText.split(/,(?=(?:[^\/]*\/[^\/]*\/)*[^\/]*$)/g)
      console.log('Grooming split:', splitCriteria)
      let criteriaToReturn = []
      for(let criterion of splitCriteria) {
        let groomed = groom(criterion)
        if (typeof(groomed) === 'boolean') {
          return groomed
          break
        } else {
          criteriaToReturn.push(groomed)
        }
      }
      return criteriaToReturn
    } else {
      console.log('Not splitting criteria: ', criteriaText)
      return groom(criteriaText)
    }
  }