import React, { useState, useEffect } from 'react'

import { Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const template = {
  ACCOUNT_ID: {
    DOM: 0,
    INTL: 0
  },
  PUBLISHER_ID: {
    DOM: '',
    INTL: ''
  }
}

export const Criteo = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  const { VENDOR } = PREBID
  const CRITEO = { ...template, ...VENDOR.CRITEO }
  const { ACCOUNT_ID = { DOM: 0, INTL: 0 }, PUBLISHER_ID = { DOM: '', INTL: '' } } = CRITEO
  const { DOM, INTL } = ACCOUNT_ID
  const { DOM: PDOM, INTL: PINTL } = PUBLISHER_ID
  const { STRING } = validation
  const { LENGTH } = STRING
  const [expanded, setExpanded] = useState(expandAllVendors)
  VENDOR.CRITEO = CRITEO
  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='Criteo-panel-content'
        id='Criteo-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>Criteo</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Table size='small' aria-label='criteo-account-id-table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '180px' }}><Typography variant='h6'>Network IDs</Typography></TableCell>
              <TableCell>Domestic</TableCell>
              <TableCell>International</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>
                <TextField
                  margin='none'
                  value={DOM}
                  error={errors.PREBID_CRITEO_AIDD}
                  helperText={errors.PREBID_CRITEO_AIDD && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    PREBID.VENDOR.CRITEO.ACCOUNT_ID.DOM = value
                    setErrors({ ...errors, PREBID_CRITEO_AIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  margin='none'
                  value={INTL}
                  error={errors.PREBID_CRITEO_AIDI}
                  helperText={errors.PREBID_CRITEO_AIDI && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    PREBID.VENDOR.CRITEO.ACCOUNT_ID.INTL = value
                    setErrors({ ...errors, PREBID_CRITEO_AIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size='small' aria-label='criteo-publisher-id-table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '180px' }}><Typography variant='h6'>Publisher IDs</Typography></TableCell>
              <TableCell>Domestic</TableCell>
              <TableCell>International</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>
                <TextField
                  margin='none'
                  value={PDOM}
                  error={errors.PREBID_CRITEO_PIDD}
                  helperText={errors.PREBID_CRITEO_PIDD && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    PREBID.VENDOR.CRITEO.PUBLISHER_ID.DOM = value
                    setErrors({ ...errors, PREBID_CRITEO_PIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  margin='none'
                  value={PINTL}
                  error={errors.PREBID_CRITEO_PIDI}
                  helperText={errors.PREBID_CRITEO_PIDI && LENGTH.message}
                  onChange={(event) => {
                    const value = event.target.value
                    PREBID.VENDOR.CRITEO.PUBLISHER_ID.INTL = value
                    setErrors({ ...errors, PREBID_CRITEO_PIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                    setOptions({ ...options, PREBID })
                    setAllOptions({ ...allOptions, PREBID })
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
