import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  siteNameFormatter,
  isObjectEquivalent,
  registryNameFormatter
} from '../../utilities/helperFunctions'
import { fetchRocketeerSites, fetchSiteIVRConfiguration, ToggleIsAdFuelVersionSelected } from '../../modules/sitesSlice'
import { fetchAdFuelVersions } from '../../modules/adFuelSlice'
import {
  fetchRegistriesForSite,
  updateSiteRegistry,
  deploySiteRegistry,
  registryImplementationProcedureUrl,
  registryWOPRv1Url,
  registryWOPRv2Url,
  registryUrl,
  ToggleInViewRefresh,
  ToggleRealTimeAdInsertion,
  ToggleSafeframeConfig,
  ToggleCustomTargetingDialog
} from '../../modules/registrySlice'
import {
  fetchPageTemplatesForSite
} from '../../modules/pageTemplateSlice'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {
  string as yupstring,
  number as yupNumber,
  object as yupobject
} from 'yup'
import { useHistory, NavLink } from 'react-router-dom'
import { getPath } from '../../modules/configSlice'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Container,
  Button,
  TextField,
  Typography,
  Breadcrumbs,
  makeStyles,
  Grid,
  Link,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { CustomTargetingAccordion } from '../sites/CustomTargetingAccordion'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HistoryIcon from '@material-ui/icons/History'
import { Slots } from '../slots'
import { ToggleSwitch, LoadingProgress } from '../ui'
import { addClasses, commonStyle } from '../../styles'

const useStyle = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300
  },
  ConfigurationItemContainer: {
    paddingTop: '4px'
  },
  ConfigurationItems: {
    margin: '2px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    border: 'none',
    boxShadow: 'none'  },
  Targetings: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  smallFont: {
    fontSize: '.8rem',
    opacity: 0.8
  },
  maxWidth: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  }
}))

const Registry = () => {
  const classes = useStyle()
  const commonClasses = commonStyle()
  const dispatch = useDispatch()
  const { site, isAdFuelVersionSelected, siteIVR } = useSelector(state => state.sitesSlice)
  const { sitePageTemplates } = useSelector(state => state.pageTemplateSlice)
  const history = useHistory()
  const {
    registry,
    isDeploying,
    loading,
    inViewRefresh,
    realTimeAdInsertion,
    hasSafeFrameConfig
  } = useSelector(state => state.registrySlice)
  const { adFuelVersions } = useSelector(state => state.adFuelSlice)
  const [edit, setEdit] = useState(false)
  const [currentRegistry, setCurrentRegistry] = useState([])
  const [originalRegistry, setOriginalRegistry] = useState('')
  const registryName = currentRegistry.name ? currentRegistry.name : 'Registry'
  const [isChanged, setIsChanged] = useState(false)
  const { pathConfig } = useSelector(state => state.configSlice)
  const [publishers, setPublishers] = useState()
  const [clickCount, setClickCount] = useState(0)
  const [loadingPage, setLoadingPage] = useState(true)

  const validationSchema = yupobject().shape({
    registryDescription: yupstring().required('Required')
  })

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    resolver: yupResolver(validationSchema),
    submitFocusError: true
  })

  // Update Registry
  const updateRegistry = () => {
    console.log('[Registry] ========== updateRegsitry::currentRegistry: ', currentRegistry)
    let newRegistry = {...currentRegistry}
    console.log ('[Registry] ========== Site: ', site)
    let hasInViewRefresh = false
    site.adFuelModules.forEach(item => {
      if (item.name === 'In-View Refresh') {
        hasInViewRefresh = true
      }
    })
    if (!hasInViewRefresh) {
      console.log('[Registry] ========== Removing In-View Refresh Settings from Registry...')
      newRegistry.hasInViewRefresh = false
      newRegistry.inViewRefreshCount = 0
      newRegistry.inViewRefreshInterval = 35
      setCurrentRegistry(newRegistry)
    }
    dispatch(updateSiteRegistry(newRegistry))
  }

  const [betaTest] = useState(
    window.localStorage.getItem('beta') === 'true'
  )
  
  const handleCustomTargetingForRegistrySave = bulkTargetings => {
    if (bulkTargetings) {
      const finalTargeting = []
      for (let y = 0; y < currentRegistry.targetings.length; y++) {
        const target = currentRegistry.targetings[y]
        if (
          Array.isArray(bulkTargetings[target.key]) &&
          bulkTargetings[target.key].length > 0
        ) {
          if (Array.isArray(target.value)) {
            for (let z = 0; z < target.value.length; z++) {
              bulkTargetings[target.key].push(target.value[z])
            }
          } else {
            bulkTargetings[target.key].push(target.value)
          }
        } else {
          if (Array.isArray(target.value)) {
            bulkTargetings[target.key] = target.value
          } else {
            bulkTargetings[target.key] = [target.value]
          }
        }
      }
      bulkTargetings.forEach(bulkTarget => {
        if (bulkTarget.key !== undefined) {
          bulkTarget.values.forEach(value => {
            const targetingObject = {
              key: bulkTarget.key,
              value: value.name
            }
            finalTargeting.push(targetingObject)
          })
        }
      })
      setCurrentRegistry({ ...currentRegistry, targetings: finalTargeting })
    }
    dispatch(ToggleCustomTargetingDialog())
  }

  const onDeploySiteRegistries = () => {
    dispatch(deploySiteRegistry(currentRegistry._id))
  }

  const onViewHistory = () => {
    history.push(
      `/sites/${siteNameFormatter(
        site.name
      )}/registries/${registryNameFormatter(registryName)}/history`
    )
  }

  const handleRegistryChange = e => {
    switch (e.target.name) {
      case 'registryName':
        setCurrentRegistry({
          ...currentRegistry,
          name: e.target.value
        })
        setValue('registryName', e.target.value, {
          shouldValidate: true
        })
        break
      case 'registryDescription':
        setCurrentRegistry({
          ...currentRegistry,
          description: e.target.value
        })
        setValue('registryDescription', e.target.value, {
          shouldValidate: true
        })
        break
      case 'registryTemplate':
        console.log('Setting Registry Template: ', e.target.value)
        setCurrentRegistry({
          ...currentRegistry,
          template: e.target.value
        })
        setValue('registryTemplate', e.target.value, {
          shouldValidate: true
        })
        break
      case 'registrySingleton':
        setCurrentRegistry({
          ...currentRegistry,
          isSingleton: e.target.checked
        })
        break
      case 'registryTagChildDirectedTreatment':
        setCurrentRegistry({
          ...currentRegistry,
          tagForChildDirectedTreatment: e.target.checked
        })
        break
      case 'registryInViewRefresh':
        setCurrentRegistry({
          ...currentRegistry,
          hasInViewRefresh: e.target.checked
        })
        toggleInViewRefresh(e.target.checked)
        break
      case 'registryRealTimeAdInsertion':
        setCurrentRegistry({
          ...currentRegistry,
          hasRealTimeAdInsertion: e.target.checked
        })
        toggleRealTimeAdInsertion(e.target.checked)
        break
      case 'registryRTAIMaxNoOfAds':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiMaxNoOfAds: e.target.value
        })
        break
      case 'registryRTAIBuffer':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiBuffer: e.target.value
        })
        break
      case 'registryRTAIOffset':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiOffset: e.target.value
        })
        break  
      case 'registryRTAIInitialPlacement':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiInitialPlacement: e.target.value
        })
        break
      case 'registryRTAIParentSelector':
        setCurrentRegistry({
          ...currentRegistry,
          rtaiParentSelector: e.target.value
        })
        break  
      case 'registryInViewRefreshCount':
        setCurrentRegistry({
          ...currentRegistry,
          inViewRefreshCount: parseInt(e.target.value, 10) >= 5 ? parseInt(e.target.value, 10) : 5
        })
        setValue('registryInViewRefreshCount', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'registryInViewDisplayPercentage':
        setCurrentRegistry({
          ...currentRegistry,
          inViewDisplayPercentage: e.target.value          
        })
        setValue('registryInViewDisplayPercentage', parseInt(e.target.value), {
          shouldValidate: true
        })
        break        
      case 'registryInViewRefreshInterval':
        setCurrentRegistry({
          ...currentRegistry,
          inViewRefreshInterval: parseInt(e.target.value, 10) >= 35 ? parseInt(e.target.value, 10): 35
        })
        setValue('registryInViewRefreshInterval', parseInt(e.target.value), {
          shouldValidate: true
        })
        break
      case 'registrySafeframeConfig':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameConfig: e.target.checked
        })
        toggleSafeframeConfig(e.target.checked)
        break
      case 'registrySandbox':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameSandboxMode: e.target.checked
        })
        break
      case 'registryAllowOverlayExpansion':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameAllowOverlayExpansion: e.target.checked
        })
        break
      case 'registryAllowPushExpansion':
        setCurrentRegistry({
          ...currentRegistry,
          safeFrameAllowPushExpansion: e.target.checked
        })
        break
      default:
        break
    }
  }

  const toggleInViewRefresh = e => {
    dispatch(ToggleInViewRefresh(e))
  }

  const toggleRealTimeAdInsertion = e => {
    dispatch(ToggleRealTimeAdInsertion(e))
  }

  const toggleSafeframeConfig = e => {
    dispatch(ToggleSafeframeConfig(e))
  }

  const siteHasModule = moduleName => {
    let hasModule = false
    if (site && site.adFuelModules) {
      site.adFuelModules.forEach(afModule => {
        if (afModule.name.indexOf(moduleName) >= 0) hasModule = true
      })
    }
    return hasModule
  }

  useEffect(() => {
    if (pathConfig && pathConfig.publisher) {
      setPublishers(pathConfig.publisher)
    } else {
      dispatch(getPath())
    }
  }, [pathConfig])

  useEffect(() => {
    if (!site._id) {
      dispatch(fetchRocketeerSites())
    }
  }, [site])

  useEffect(() => {
    if (site._id) {
      dispatch(fetchRegistriesForSite(site._id, true))
      console.log('[Registry] ---------- useEffect[site]::Fetching Site IVR Configuration...')
      dispatch(fetchSiteIVRConfiguration(site._id))
      dispatch(fetchPageTemplatesForSite(site._id))
    }
  }, [site])

  useEffect(() => {
    console.log('[Registry] ---------- useEffect[siteIVR]::Setting IVR Values...', { currentRegistry, siteIVR })
    setValue(
      'registryInViewRefreshCount',
      parseInt(currentRegistry.inViewRefreshCount) === 0
        ? (parseInt(siteIVR.DEFAULT_REFRESH_COUNT, 10) || 6)
        : parseInt(currentRegistry.inViewRefreshCount, 10),
      {
        shouldValidate: true
      }
    )
    setValue(
      'registryInViewRefreshInterval',
      parseInt(currentRegistry.inViewRefreshInterval) === 0
        ? (parseInt(siteIVR.DEFAULT_DISPLAY_MILLISECONDS/1000, 10) || 35)
        : parseInt(currentRegistry.inViewRefreshInterval/1000, 10),
      {
        shouldValidate: true
      }
    )
    setValue(
      'registryInViewDisplayPercentage',
      parseInt(currentRegistry.inViewDisplayPercentage) === 0
        ? (parseInt(siteIVR.DEFAULT_DISPLAY_PERCENT, 10) || 25)
        : parseInt(currentRegistry.inViewDisplayPercentage, 10),
      {
        shouldValidate: true
      }
    )
    setCurrentRegistry({
      ...currentRegistry,
      inViewRefreshCount: parseInt(currentRegistry.inViewRefreshCount) === 0
      ? (parseInt(siteIVR.DEFAULT_REFRESH_COUNT, 10) || 6)
      : parseInt(currentRegistry.inViewRefreshCount, 10),
      inViewRefreshInterval: parseInt(currentRegistry.inViewRefreshInterval) === 0
      ? (parseInt(siteIVR.DEFAULT_DISPLAY_MILLISECONDS/1000, 10) || 35)
      : parseInt(currentRegistry.inViewRefreshInterval/1000, 10),
      inViewDisplayPercentage: parseInt(currentRegistry.inViewDisplayPercentage) === 0
      ? (parseInt(siteIVR.DEFAULT_DISPLAY_PERCENT, 10) || 25)
      : parseInt(currentRegistry.inViewDisplayPercentage, 10),
    })
    console.log('[Registry] ---------- useEffect[siteIVR]::currentRegistry: ', JSON.parse(JSON.stringify(currentRegistry), null, 2))
  }, [siteIVR])

  useEffect(() => {
    if (isObjectEquivalent(currentRegistry, originalRegistry)) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
  }, [currentRegistry, originalRegistry])

  useEffect(() => {
    if (registry) {
      setCurrentRegistry(registry)
      setOriginalRegistry(registry)
      toggleSafeframeConfig(registry?.safeFrameConfig)
      toggleInViewRefresh(registry?.hasInViewRefresh)
      toggleRealTimeAdInsertion(registry?.hasRealTimeAdInsertion)
      setValue('registryDescription', registry.description, {
        shouldValidate: true
      })
    }
  }, [registry, clickCount])

  useEffect(() => {
    if (!loading) {
      setEdit(true)
    }
  }, [loading])

  useEffect(() => {
    if (adFuelVersions.length === 0) {
      dispatch(fetchAdFuelVersions())
    } else {
      const adFuelVersion = adFuelVersions.find(adfuel => adfuel._id === site.adFuelVersionId)
      dispatch(ToggleIsAdFuelVersionSelected(adFuelVersion !== undefined))
    }
  }, [adFuelVersions, site])

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false)
    }, 2000)
  }, [])
  console.log('[Registry] ---------- render::site: ', site)
  console.log('[Registry] ---------- render::currentRegsitry: ', currentRegistry)
  if (site.useWOPR && currentRegistry.name) {
    console.log('[Registry] ---------- render::WOPR Path Segments: ', currentRegistry.name?.split('_'))
  }
  console.log('[Registry] ---------- render::siteIVR: ', siteIVR)
  console.log('[Registry] ---------- sitePageTemplates: ', sitePageTemplates)
  return (
    <div className='registry'>
      <Breadcrumbs
        className={addClasses(commonClasses.pl_3, commonClasses.pb_1, loadingPage ? (commonClasses.disabledlink, commonClasses.opacity50) : null)}
        aria-label='breadcrumb'
      >
        {/* <NavLink to={`/sites/${siteNameFormatter(site.name)}`}>
          {site.name ? site.name : 'Site'}
        </NavLink>
        <NavLink to={`/sites/${siteNameFormatter(site.name)}/registries`}>
          Registries
        </NavLink>
        <NavLink
          activeStyle={{
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
          to={`/sites/${siteNameFormatter(
            site.name
          )}/registries/${registryNameFormatter(registryName)}`}
        >
          {registryName}
        </NavLink> */}
        <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}`} disabled={loadingPage} onClick={loadingPage ? (event) => event.preventDefault() : null}>{site.name ? site.name : 'Site'} </NavLink>
        <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`} disabled={loadingPage} onClick={loadingPage ? (event) => event.preventDefault() : null}>Registries</NavLink>        
        <NavLink to={`/sites/${siteNameFormatter(site.name)}/registries/${registry.isSingleton ? 'singleton/' : 'registry/'}${registryNameFormatter(registryName)}`} disabled={loadingPage} onClick={loadingPage ? (event) => event.preventDefault() : null}>{registryName}</NavLink>
      </Breadcrumbs>
      <Container maxWidth='xl'>
        <form autoComplete='off' noValidate>
          {currentRegistry.name === undefined || loading
            ? (
              <LoadingProgress label='Loading Registry Information' />
              )
            : (
              <Card>
                <CardHeader
                  title={`[${siteNameFormatter(site.name).toUpperCase()}] ${
                  currentRegistry.name
                }`}
                  action={
                    <>
                      <Button
                        className={
                        isChanged
                          ? [commonClasses.btnSuccess, commonClasses.mr_1].join(' ')
                          : commonClasses.mr_1
                      }
                        disabled={!isChanged || loading}
                        title='Save'
                        variant='contained'
                        onClick={handleSubmit(updateRegistry)}
                        startIcon={<CheckCircleIcon />}
                      >
                        Save
                      </Button>
                      {isDeploying
                        ? (
                          <Button
                            disabled
                            variant='contained'
                            title='Deploying...'
                            className={[
                              commonClasses.btnSuccess,
                              commonClasses.mr_1
                            ].join(' ')}
                            startIcon={<CircularProgress size={24} />}
                          >
                            Deploy
                          </Button>
                          )
                        : currentRegistry.isChangedSinceDeployment
                          ? (
                            <Button
                              disabled={isChanged || !isAdFuelVersionSelected}
                              variant='contained'
                              title='Deploy'
                              className={[
                                commonClasses.btnWarning,
                                commonClasses.mr_1
                              ].join(' ')}
                              startIcon={<OfflineBoltIcon />}
                              onClick={() => {
                                onDeploySiteRegistries()
                              }}
                            >
                              Deploy
                            </Button>
                            )
                          : (
                            <Button
                              disabled={isChanged || !isAdFuelVersionSelected}
                              variant='contained'
                              title='Deploy'
                              className={[
                                commonClasses.btnSuccess,
                                commonClasses.mr_1
                              ].join(' ')}
                              startIcon={<OfflineBoltIcon />}
                              onClick={() => {
                                onDeploySiteRegistries()
                              }}
                            >
                              Deploy
                            </Button>
                            )}
                      {
                        <Button
                          variant='contained'
                          title='View History'
                          className={[
                            commonClasses.btnInfo,
                            commonClasses.mr_1
                          ].join(' ')}
                          startIcon={<HistoryIcon size={24} />}
                          onClick={() => {
                            onViewHistory()
                          }}
                        >
                          View History
                        </Button>
                    }
                    </>
                }
                />
                <Divider />
                <CardContent className={commonClasses.cardBkClr}>
                  <Card key={'registryGeneral'}>
                    <CardContent>
                      <Grid container key={'name'}>
                        <Grid item md={6} xs={6} style={{ marginBottom: '10pt' }}>
                          <TextField
                            disabled
                            fullWidth
                            label='Name'
                            margin='dense'
                            id='registryName'
                            name='registryName'
                            variant='standard'
                            value={currentRegistry.name}
                          />
                        </Grid>
                        <Grid item md={5} xs={5} style={{ margin: '10pt' }}>
                          <>
                            <Typography className={classes.link}>
                              <Link
                                className={commonClasses.linkColor}
                                href={registryUrl(
                                  siteNameFormatter(site.name),
                                  true,
                                  site.isRegistryMinified,
                                  site.areLinksSecure,
                                  currentRegistry,
                                  publishers
                                )}
                                rel='noreferrer'
                                target='_blank'
                                onClick={() => {
                                  setClickCount(clickCount + 1)
                                }}
                              >
                                {registryUrl(
                                  siteNameFormatter(site.name),
                                  false,
                                  site.isRegistryMinified,
                                  site.areLinksSecure,
                                  currentRegistry,
                                  publishers
                                )}
                              </Link>
                            </Typography>
                            <Typography className={classes.link}>
                              <Link
                                className={commonClasses.linkColor}
                                href={registryImplementationProcedureUrl(
                                  siteNameFormatter(site.name),
                                  true,
                                  site.areLinksSecure,
                                  currentRegistry,
                                  publishers
                                )}
                                onClick={() => {
                                  setClickCount(clickCount + 1)
                                }}
                                rel='noreferrer'
                                target='_blank'
                              >
                                {registryImplementationProcedureUrl(
                                  siteNameFormatter(site.name),
                                  false,
                                  site.areLinksSecure,
                                  currentRegistry,
                                  publishers
                                )}
                                {currentRegistry.isSingleton ? ' (SIP)' : ' (RIP)'}
                              </Link>
                            </Typography>
                          </>
                          {site.useWOPR ? (
                            <>
                              <Typography className={classes.link}>
                                <Link
                                  className={commonClasses.linkColor}
                                  href={registryWOPRv1Url(site, currentRegistry, publishers)}
                                  onClick={() => {
                                    setClickCount(clickCount + 1)
                                  }}
                                  rel='noreferrer'
                                  target='_blank'
                                >
                                  {registryWOPRv1Url(site, currentRegistry, publishers)}
                                </Link>
                              </Typography>
                              <Typography className={classes.link}>
                                <Link
                                  className={commonClasses.linkColor}
                                  href={registryWOPRv2Url(site, currentRegistry, publishers)}
                                  onClick={() => {
                                    setClickCount(clickCount + 1)
                                  }}
                                  rel='noreferrer'
                                  target='_blank'
                                >
                                  {registryWOPRv2Url(site, currentRegistry, publishers)}
                                </Link>
                              </Typography>
                            </>
                          ): ''}
                        </Grid>
                      </Grid>
                      <Grid container key={'description'}>
                        <Grid item md={6} xs={6}>
                          {edit
                            ? (
                              <TextField
                                fullWidth
                                label='Description'
                                margin='dense'
                                id='registryDescription'
                                variant='standard'
                                value={currentRegistry.description}
                                {...register('registryDescription')}
                                onChange={e => handleRegistryChange(e)}
                                error={!!errors.registryDescription}
                                helperText={
                              errors.registryDescription
                                ? errors.registryDescription.message
                                : ''
                            }
                              />
                              )
                            : (
                              <Typography paragraph>
                                Description: {currentRegistry.description}
                              </Typography>
                              )}
                        </Grid>
                        {site.useWOPR ? (
                          <Grid item md={5} xs={5} style={{margin: 2, padding: 2}}>
                            <FormControl
                              style={{ width: '70%' }}
                              className={classes.formControl}
                            >
                              <InputLabel id='select-registryTemplate'>Template</InputLabel>
                              <Select
                                {...register('registryTemplate', {
                                  validate: value => value !== ''
                                })}
                                labelId='select-registryTemplate'
                                style={{ width: '99%', marginTop: '16px' }}
                                id='registryTemplate'
                                value={currentRegistry.template}
                                onChange={e => handleRegistryChange(e)}
                                error={!!errors.registryTemplate}
                                // disabled={true}
                              >
                                <MenuItem key={-1} value='' />
                                {sitePageTemplates.map(template => (
                                  <MenuItem key={template._id} value={template._id}>
                                    {template.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        ): ''}
                      </Grid>
                      <Grid container key={'tagForChildDirectedTreatment-singleton'} style={{borderBottom: 'solid 1px rgba(0,0,0,0.54)', borderCollapse: 'collapse' }}>
                        <Grid item md={2} xs={2}>
                          <ToggleSwitch
                            label='Child-Directed Treatment'
                            name='registryTagChildDirectedTreatment'
                            offLabel='No'
                            onLabel='Yes'
                            checked={!!currentRegistry.tagForChildDirectedTreatment}
                            onChange={e => handleRegistryChange(e)}
                            value={currentRegistry.tagForChildDirectedTreatment}
                          />
                        </Grid>
                        <Grid item md={3} xs={3}>
                          <ToggleSwitch
                            disabled={
                            currentRegistry.slots &&
                            currentRegistry.slots.length > 0
                          }
                            label='Singleton'
                            name='registrySingleton'
                            offLabel='No'
                            onLabel='Yes'
                            checked={!!currentRegistry.isSingleton}
                            onChange={e => {
                              handleRegistryChange(e)
                            }}
                          />
                        </Grid>                    
                      </Grid>
                      {siteHasModule('In-View Refresh') ? (
                        <Grid container key={'inViewRefresh'} style={{borderBottom: 'solid 1px rgba(0,0,0,0.54)', borderCollapse: 'collapse' }}>
                          <Grid item md={2} xs={2}>
                            <ToggleSwitch
                              label='In-View Refresh'
                              name='registryInViewRefresh'
                              offLabel='Disabled'
                              onLabel='Enabled'
                              checked={!!currentRegistry.hasInViewRefresh}
                              onChange={e => {
                                handleRegistryChange(e)
                              }}
                              value={!!currentRegistry.hasInViewRefresh}
                            />
                          </Grid>
                          <Grid item md={10} xs={10}>
                            {inViewRefresh
                              ? (
                                <Grid container>
                                  <Grid item md={2} xs={2} className={classes.ConfigurationItemContainer}>
                                    {currentRegistry.hasInViewRefresh
                                      ? (
                                        <>
                                          <Paper className={classes.ConfigurationItems}>
                                            <TextField
                                              {...register('registryInViewRefreshCount')}
                                              className={classes.input}
                                              label='Refresh Count'
                                              margin='dense'
                                              id='registryInViewRefreshCount'
                                              variant='standard'
                                              onChange={e => {
                                                handleRegistryChange(e)
                                              }}
                                              value={
                                                currentRegistry.inViewRefreshCount === 0
                                                  ? (siteIVR?.DEFAULT_REFRESH_COUNT || 5)
                                                  : currentRegistry.inViewRefreshCount
                                              }
                                              type='number'
                                              InputProps={{
                                                inputProps: { min: '5', step: '1' }
                                              }}
                                              error={!!errors.registryInViewRefreshCount}
                                              helperText={
                                                errors.registryInViewRefreshCount
                                                  ? errors.registryInViewRefreshCount.message
                                                  : ''
                                              }
                                            />
                                            <Divider
                                              className={commonClasses.verticalDivider28}
                                              orientation='vertical'
                                            />
                                            <span className={classes.smallFont}>times</span>
                                          </Paper>
                                        </>
                                        )
                                      : (
                                        <></>
                                        )}
                                  </Grid>
                                  <Grid item md={2} xs={2} className={classes.ConfigurationItemContainer}>
                                    {currentRegistry.hasInViewRefresh
                                      ? (
                                        <>
                                          <Paper className={classes.ConfigurationItems}>
                                            <TextField
                                              {...register('registryInViewRefreshInterval')}
                                              className={classes.input}
                                              label='Refresh Interval'
                                              margin='dense'
                                              id='registryInViewRefreshInterval'
                                              variant='standard'
                                              type='number'
                                              InputProps={{
                                                inputProps: { min: '35', step: '1' }
                                              }}
                                              onChange={e => {
                                                handleRegistryChange(e)
                                              }}
                                              value={
                                                !currentRegistry.inViewRefreshInterval
                                                  ? (siteIVR?.DEFAULT_DISPLAY_MILLISECONDS / 1000)
                                                  : currentRegistry.inViewRefreshInterval
                                              }
                                              error={!!errors.registryInViewRefreshInterval}
                                              helperText={
                                                errors.registryInViewRefreshInterval
                                                  ? errors.registryInViewRefreshInterval.message
                                                  : ''
                                              }
                                            />
                                            <Divider
                                              className={commonClasses.verticalDivider28}
                                              orientation='vertical'
                                            />
                                            <span className={classes.smallFont}>seconds</span>
                                          </Paper>
                                        </>
                                        )
                                      : (
                                        <></>
                                        )}
                                  </Grid>
                                </Grid>
                                )
                              : (
                                <></>
                                )}
                          </Grid>
                        </Grid>
                      ): ''}
                      {siteHasModule('Real-Time Ad Insertion') ? (
                        <Grid container key={'realTimeAdInsertion'} style={{borderBottom: 'solid 1px rgba(0,0,0,0.54)', borderCollapse: 'collapse' }}>
                          <Grid item md={2} xs={2}>
                            <ToggleSwitch
                              label='Real-Time Ad Insertion'
                              name='registryRealTimeAdInsertion'
                              offLabel='Disabled'
                              onLabel='Enabled'
                              checked={!!currentRegistry.hasRealTimeAdInsertion}
                              onChange={e => {
                                handleRegistryChange(e)
                              }}
                              value={!!currentRegistry.hasRealTimeAdInsertion}
                            />
                          </Grid>
                          <Grid item md={10} xs={10}>
                            {realTimeAdInsertion
                              ? (
                                <Grid container>
                                  <Grid item md={2} xs={2} key={'rtaiMaxNoOfAdsItem'} className={classes.ConfigurationItemContainer}>
                                    {currentRegistry.hasRealTimeAdInsertion
                                      ? (
                                        <Paper className={classes.ConfigurationItems}>
                                          <TextField
                                            {...register('registryRTAIMaxNoOfAds')}
                                            className={classes.input}
                                            label='Max # of Ads'
                                            margin='dense'
                                            id='registryRTAIMaxNoOfAds'
                                            variant='standard'
                                            onChange={e => {
                                              handleRegistryChange(e)
                                            }}
                                            value={
                                              currentRegistry.rtaiMaxNoOfAds                                            
                                                ? currentRegistry.rtaiMaxNoOfAds
                                                : 6
                                            }
                                            type='number'
                                            InputProps={{
                                              inputProps: { min: '1', step: '1' }
                                            }}
                                            error={!!errors.registryRTAIMaxNoOfAds}
                                            helperText={
                                              errors.registryRTAIMaxNoOfAds
                                                ? errors.registryRTAIMaxNoOfAds.message
                                                : ''
                                            }
                                          />
                                        </Paper>
                                        )
                                      : (
                                        <></>
                                        )
                                    }
                                  </Grid>
                                  <Grid item md={2} xs={2} key={'rtaiBuffer'} className={classes.ConfigurationItemContainer}>
                                    {currentRegistry.hasRealTimeAdInsertion
                                      ? (
                                        <Paper className={classes.ConfigurationItems}>
                                          <TextField
                                            {...register('registryRTAIBuffer')}
                                            className={classes.input}
                                            label='Buffer'
                                            margin='dense'
                                            id='registryRTAIBuffer'
                                            variant='standard'
                                            onChange={e => {
                                              handleRegistryChange(e)
                                            }}
                                            value={
                                              currentRegistry.rtaiBuffer === 0
                                                ? 4
                                                : currentRegistry.rtaiBuffer
                                            }
                                            type='number'
                                            InputProps={{
                                              inputProps: { min: '1', step: '1' }
                                            }}
                                            error={!!errors.registryRTAIBuffer}
                                            helperText={
                                              errors.registryRTAIBuffer
                                                ? errors.registryRTAIBuffer.message
                                                : ''
                                            }
                                          />
                                        </Paper>
                                      )
                                      : (
                                        <></>
                                      )
                                    }
                                  </Grid>
                                  <Grid item md={2} xs={2} key={'rtaiOffset'} className={classes.ConfigurationItemContainer}>
                                  {currentRegistry.hasRealTimeAdInsertion
                                      ? (
                                        <Paper className={classes.ConfigurationItems}>
                                          <TextField
                                            {...register('registryRTAIOffset')}
                                            className={classes.input}
                                            label='Offset'
                                            margin='dense'
                                            id='registryRTAIOffset'
                                            variant='standard'
                                            onChange={e => {
                                              handleRegistryChange(e)
                                            }}
                                            value={
                                              currentRegistry.rtaiOffset === 0
                                                ? 2
                                                : currentRegistry.rtaiOffset
                                            }
                                            type='number'
                                            InputProps={{
                                              inputProps: { min: '1', step: '1' }
                                            }}
                                            error={!!errors.registryRTAIOffset}
                                            helperText={
                                              errors.registryRTAIOffset
                                                ? errors.registryRTAIOffset.message
                                                : ''
                                            }
                                          />
                                        </Paper>
                                      )
                                      : (
                                        <></>
                                      )
                                    }
                                  </Grid>
                                  <Grid item md={2} xs={2} key={'rtaiInitialPlacement'} className={classes.ConfigurationItemContainer}>
                                    {currentRegistry.hasRealTimeAdInsertion
                                      ? (
                                        <Paper className={classes.ConfigurationItems}>
                                          <TextField
                                            {...register('registryRTAIInitialPlacement')}
                                            className={classes.input}
                                            label='Initial Placement'
                                            margin='dense'
                                            id='registryRTAIInitialPlacement'
                                            variant='standard'
                                            onChange={e => {
                                              handleRegistryChange(e)
                                            }}
                                            value={
                                              currentRegistry.rtaiInitialPlacement === 0
                                                ? 4
                                                : currentRegistry.rtaiInitialPlacement
                                            }
                                            type='number'
                                            InputProps={{
                                              inputProps: { min: '1', step: '1' }
                                            }}
                                            error={!!errors.registryRTAIInitialPlacement}
                                            helperText={
                                              errors.registryRTAIInitialPlacement
                                                ? errors.registryRTAIInitialPlacement.message
                                                : ''
                                            }
                                          />
                                        </Paper>
                                      )
                                      : (
                                        <></>
                                        )
                                    }
                                  </Grid>
                                  <Grid item md={4} xs={4} key={'rtaiParentSelector'} className={classes.ConfigurationItemContainer}>
                                    {currentRegistry.hasRealTimeAdInsertion
                                      ? (
                                        <Paper className={classes.ConfigurationItems}>
                                          <TextField
                                            fullWidth
                                            label='Parent Selector'
                                            margin='dense'
                                            id='registryRTAIParentSelector'
                                            variant='standard'
                                            value={currentRegistry.rtaiParentSelector}
                                            {...register('registryRTAIParentSelector')}
                                            onChange={e => handleRegistryChange(e)}
                                            error={!!errors.registryRTAIParentSelector}
                                            helperText={
                                              errors.registryRTAIParentSelector
                                                ? errors.registryRTAIParentSelector.message
                                                : ''
                                            }
                                          />
                                        </Paper>
                                      )
                                      : (
                                        <></>
                                      )
                                    }
                                  </Grid>
                                </Grid>
                                )
                              : (
                                <></>
                                )}
                          </Grid>
                        </Grid>
                      ): ''}
                      <Grid container key={'safeframeConfig'}>
                        <Grid item md={2} xs={2}>
                          <ToggleSwitch
                            label='Safeframe Config'
                            name='registrySafeframeConfig'
                            offLabel='Disabled'
                            onLabel='Enabled'
                            checked={!!currentRegistry.safeFrameConfig}
                            onChange={e => handleRegistryChange(e)}
                            value={currentRegistry.safeFrameConfig}
                          />
                        </Grid>
                        <Grid item md={10} xs={10}>
                          {hasSafeFrameConfig 
                            ? (
                              <Grid container>
                                <Grid item md={2} xs={2} className={classes.ConfigurationItemContainer}>
                                <Paper className={classes.ConfigurationItems}>
                                  <ToggleSwitch
                                    label='Sandbox'
                                    name='registrySandbox'
                                    offLabel='No'
                                    onLabel='Yes'
                                    checked={!!currentRegistry.safeFrameSandboxMode}
                                    onChange={e => handleRegistryChange(e)}
                                    value={currentRegistry.safeFrameSandboxMode}
                                    smallLabel
                                  />
                                </Paper>
                                </Grid>
                                <Grid item md={2} xs={2} className={classes.ConfigurationItemContainer}>
                                <Paper className={classes.ConfigurationItems}>
                                  <ToggleSwitch
                                    label='Allow Overlay Expansion'
                                    name='registryAllowOverlayExpansion'
                                    offLabel='No'
                                    onLabel='Yes'
                                    checked={!!currentRegistry.safeFrameAllowOverlayExpansion}
                                    onChange={e => handleRegistryChange(e)}
                                    value={currentRegistry.safeFrameAllowOverlayExpansion}
                                    smallLabel
                                  />
                                </Paper>
                                </Grid>
                                <Grid item md={2} xs={2} className={classes.ConfigurationItemContainer}>
                                <Paper className={classes.ConfigurationItems}>
                                  <ToggleSwitch
                                    label='Allow Push Expansion'
                                    name='registryAllowPushExpansion'
                                    offLabel='No'
                                    onLabel='Yes'
                                    checked={
                                      !!currentRegistry.safeFrameAllowPushExpansion
                                    }
                                    onChange={e => handleRegistryChange(e)}
                                    value={currentRegistry.safeFrameAllowPushExpansion}
                                    smallLabel
                                  />
                                </Paper>
                                </Grid>
                              </Grid>
                            )
                            : (
                              <></>
                            )
                          }
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card><br />
                  <Card key={'registryTargetings'}>
                    <CardContent>
                      <CustomTargetingAccordion
                        currentTargetings={currentRegistry?.targetings}
                        isSlotTargeting={false}
                        handleSave={handleCustomTargetingForRegistrySave}
                        key={'customTargeting'}
                      />
                    </CardContent>
                  </Card><br />
                  <Card key={'registrySlots'}>
                    <CardContent>
                    <Slots isSingleton={currentRegistry.isSingleton} isRegistryChanged={isChanged} />
                    </CardContent>
                  </Card>
                </CardContent>
              </Card>
              )}
        </form>
      </Container>
    </div>
  )
}

export default Registry
