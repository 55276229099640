import React, { useState } from 'react'

//import Swal from 'sweetalert2'

import { 
  Grid,
  IconButton, 
  List,
  ListItem, 
  TextField,
  Tooltip
} from '@material-ui/core'

import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import { commonStyle } from '../../styles/index.js'

//const swal = withReactContent(Swal)

export default function CriterionList({criteria, removeCriteria, addCriteria}) {
  const commonClasses = commonStyle()

  const [entry, setEntry] = useState({criterion:'',description:''})

  return (
    <List dense disablePadding>
      {criteria.map((item, index) => {
        return (
          <ListItem dense disableGutters key={index}>
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  style={{ width: '300px' }}
                  value={item.criterion}
                  onChange={(event) => {

                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '300px' }}
                  value={item.description}
                  onChange={(event) => {

                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title='Delete'>
                  <IconButton
                    className={commonClasses.btnDanger}
                    size='small'
                    onClick={() => {
                      // swal.fire({
                      //   title: 'Are you sure?',
                      //   icon: 'warning',
                      //   showCancelButton: true
                      // }).then(ok => {
                      //   if (ok.isConfirmed) {
                          removeCriteria(index)
                      //   }
                      // })
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </ListItem>
        )
      })}
      <ListItem dense disableGutters>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              style={{ width: '300px' }}
              placeholder='Criterion'
              value={entry.criterion}
              onChange={(event) => {
                setEntry({...entry, criterion: event.target.value})
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '300px' }}
              placeholder='Description'
              value={entry.description}
              onChange={(event) => {
                setEntry({...entry, description:event.target.value})
              }}
            />
          </Grid>
          <Grid item>
            <Tooltip title='Add'>
              <IconButton
                className={commonClasses.btnSuccess}
                size='small'
                onClick={() => {
                  if (entry.criterion) {
                    addCriteria(entry)
                    setEntry({criterion:'',description:''})
                  }
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  )
}
