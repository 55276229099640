import React, { useState, useEffect } from 'react'

import { Grid, Table, TableHead, TableBody, TableRow, TableCell, TextField, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const template = {
  SUPPLY_SOURCE_ID: {
    DOM: 'supplier',
    INTL: 'supplier'
  },
  PUBLISHER_ID: {
    DOM: '0',
    INTL: '0'
  }
}

export const TheTradeDesk = ({ expandAllVendors, options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  const { PREBID } = options
  // console.log('Prebid: ', PREBID)
  const { VENDOR } = PREBID
  VENDOR.TTD = { ...template, ...VENDOR.TTD }
  const { TTD } = VENDOR
  const { SUPPLY_SOURCE_ID, PUBLISHER_ID } = TTD
  const { STRING } = validation
  const { LENGTH } = STRING
  const [expanded, setExpanded] = useState(expandAllVendors)

  useEffect(() => {
    setExpanded(expandAllVendors)
  }, [expandAllVendors])

  const displaySupplySourceIdTable = () => {
    return (
      <Table size='small' aria-label='ttd-supply-source-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Supply Source IDs</Typography></TableCell>
            <TableCell>Domestic</TableCell>
            <TableCell>International</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>
              <TextField
                margin='none'
                value={SUPPLY_SOURCE_ID.DOM}
                error={errors.PREBID_TTD_SSIDD}
                helperText={errors.PREBID_TTD_SSIDD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TTD.SUPPLY_SOURCE_ID.DOM = value
                  setErrors({ ...errors, PREBID_TTD_SSIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                margin='none'
                value={SUPPLY_SOURCE_ID.INTL}
                error={errors.PREBID_TTD_SSIDI}
                helperText={errors.PREBID_TTD_SSIDI && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TTD.SUPPLY_SOURCE_ID.INTL = value
                  setErrors({ ...errors, PREBID_TTD_SSIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const displayPublisherIdTable = () => {
    return (
      <Table size='small' aria-label='ttd-publisher-id-table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '180px' }}><Typography variant='h6'>Publisher IDs</Typography></TableCell>
            <TableCell>Domestic</TableCell>
            <TableCell>International</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell>
              <TextField
                margin='none'
                value={PUBLISHER_ID.DOM}
                error={errors.PREBID_TTD_PIDD}
                helperText={errors.PREBID_TTD_PIDD && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TTD.PUBLISHER_ID.DOM = value
                  setErrors({ ...errors, PREBID_TTD_PIDD: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
            <TableCell>
              <TextField
                margin='none'
                value={PUBLISHER_ID.INTL}
                error={errors.PREBID_TTD_PIDI}
                helperText={errors.PREBID_TTD_PIDI && LENGTH.message}
                onChange={(event) => {
                  const value = event.target.value
                  PREBID.VENDOR.TTD.PUBLISHER_ID.INTL = value
                  setErrors({ ...errors, PREBID_TTD_PIDI: value.length < LENGTH.min || value.length > LENGTH.max })
                  setOptions({ ...options, PREBID })
                  setAllOptions({ ...allOptions, PREBID })
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
  return (
    <ExpansionPanel expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='ttd-panel-content'
        id='ttd-panel-header'
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant='h5'>TheTradeDesk</Typography>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{displaySupplySourceIdTable()}</Grid>
          <Grid item xs={12}>{displayPublisherIdTable()}</Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
